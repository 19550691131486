<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="2"
            block
            x-large
            color="#F2C94C"
            v-bind="attrs"
            v-on="on"
            :disabled="isDisabled"
          >
            <span class="white--text">アウト</span>
          </v-btn>
        </template>
        <v-card>
          <div class="justify-end d-flex">
            <div class="justify-end d-flex">
              <v-btn icon color="white" @click="dialog = false">
                <v-avatar color="#FF8585" size="15">
                  <v-icon small>mdi-close</v-icon></v-avatar
                >
              </v-btn>
            </div>
          </div>
          <v-card-title class="text-h5 justify-center d-flex">
            <div class="text-subtitle-1 text-primary">
              <p class="text-heading">この勤怠をアウトしますか？</p>
            </div>
            <div class="text-subtitle-2 text-primary">
              <p class="text-subheading">
                この操作により勤怠報告が完了します。
              </p>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="my-0">
              <v-col cols="12" sm="12">
                <div>
                  <MessageDialog
                    @hideDialog="hideThisDialog"
                    :isShow="isConfirmDialogShow"
                  />
                </div>
                <div class="text-center mt-5">
                  <div class="grey--text" @click="dialog = false">← 戻る</div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import MessageDialog from "./MessageDialog.vue";
export default {
  components: { MessageDialog },
  props: {
    worktime: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      isConfirmDialogShow: true,
      types: [
        { text: "デイレスト", value: 1 },
        { text: "夜の休息", value: 2 },
      ],
    };
  },

  computed: {
    isDisabled() {
      if (this.worktime && this.worktime.clock_in && !this.worktime.clock_out) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    hideThisDialog(isShow) {
      this.isConfirmDialogShow = true;
      this.dialog = isShow;
      this.$parent.$emit("submit");
    },
  },
};
</script>

<style>
.text-heading {
  font-weight: bold !important;
}
.text-subheading {
  font-size: 0.7rem !important;
  font-weight: 400;
  color: #909090 !important;
}
</style>
