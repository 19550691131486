<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog max-width="290">
        <template v-slot:activator="{ on, test }">
          <v-btn
            elevation="2"
            block
            x-large
            color="green accent-4"
            v-bind="test"
            v-on="on"
            :disabled="isRestDisabled"
            @click="submitRest"
          >
            <span class="white--text">休憩を追加</span>
          </v-btn>
        </template>
        <v-card class="dialog-card d-flex thumb-content">
          <div>
            <v-img
              lazy-src="https://picsum.photos/id/11/10/6"
              max-height="150"
              max-width="150"
              class="thumb-img"
              :src="require('@/assets/images/coffee_1.png')"
            ></v-img>
          </div>
          <div>
            <p class="text-center footer-text">休憩を追加しました！</p>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    rest: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    submitRest() {
      this.$emit("submit");
    },
  },
  computed: {
    isRestDisabled() {
      return this.rest.type != null &&
        this.rest.resting_minutes &&
        this.rest.resting_minutes != 0
        ? false
        : true;
    },
  },
};
</script>

<style scoped>
.dialog-card {
  justify-content: center !important;
  height: 22rem !important;
}
.thumb-content {
  flex-direction: column;
  align-items: center;
}
.footer-text {
  margin-top: 2rem;
  font-weight: bold;
}
</style>
