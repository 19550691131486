<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ClockInformationCard
          :clock-type="clockType"
          :worktime="worktime"
          cardColor="#FFFFF0"
        />
      </v-col>
      <v-col cols="12">
        <ClockTimeCard :worktime="worktime" @update="updateTime" />
      </v-col>
      <v-col cols="12">
        <ClockOutActionButton :worktime="worktime" :task_id="worktime.task_id" @submit="clockOut" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClockInformationCard from "@/components/layout/clocks/main/ClockInformationCard";
import ClockTimeCard from "@/components/layout/clocks/main/ClockTimeCard";
import ClockOutActionButton from "@/components/layout/clocks/clockOut/ClockOutActionButton";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  components: { ClockOutActionButton, ClockInformationCard, ClockTimeCard },
  data() {
    return {
      clockType: null,
      formData: {
        // task_id: null,
        worktime_id: this.$route.params.worktime_id,
        clock_out: dayjs().format("H:mm"),
      },
    };
  },
  created() {
    this.getWorkTime();
  },
  computed: {
    ...mapGetters(["worktime"]),
  },
  methods: {
    async getTaskById() {
      let id = this.formData.task_id;
      await this.$store.dispatch("GET_TASK_BY_ID", {
        id: id,
        data: { page: "clock" },
      });
    },
    async getWorkTime() {
      let id = this.formData.worktime_id;
      await this.$store.dispatch("WORKTIME_GET", id);
      // await this.$store.dispatch("WORKTIME_BY_TASK_ID_GET", task_id);
    },
    updateTime(time) {
      this.formData.clock_out = time;
    },
    clockOut() {
      this.formData.task_id = this.worktime.task_id;
      this.$store.dispatch("CLOCK_OUT", this.formData).then((result) => {
        // this.loading = false;
        if (result.status === 200) {
          setTimeout(() => {
            // this.dialog = false;
            this.$router.push({
              name: "tasks"
            });
          }, 2000);
        }
      });
    },
  },
};
</script>

<style></style>
