<template>
  <v-container fluid class="px-0">
    <ClockOut />
  </v-container>
</template>

<script>
import ClockOut from "@/components/clocks/ClockOut";
export default {
  name: "Clock",
  components: { ClockOut }
};
</script>

<style scoped></style>
