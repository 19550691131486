<template>
  <v-container v-if="!loading" fluid>
    <v-row justify="center">
      <v-dialog v-model="isDialogShow" max-width="290">
        <template v-slot:activator="{ on, test }">
          <v-btn
            elevation="2"
            block
            x-large
            color="amber accent-3"
            v-bind="test"
            v-on="on"
            @click="hideParentDialog"
          >
            <span class="white--text">アウト</span>
          </v-btn>
        </template>
        <v-card class="dialog-card d-flex thumb-content">
          <div>
            <v-img
              max-height="150"
              max-width="150"
              class="thumb-img"
              :src="require('@/assets/images/like_1.png')"
            ></v-img>
          </div>
          <div>
            <p class="text-center footer-text">お疲れ様でした！</p>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isDialogShow: false,
    };
  },
  methods: {
    hideParentDialog() {
      this.isDialogShow = this.isShow;
      this.$emit("hideDialog", false);
    },
  },
};
</script>

<style scoped>
.dialog-card {
  justify-content: center !important;
  height: 22rem !important;
}
.thumb-content {
  flex-direction: column;
  align-items: center;
}
.footer-text {
  margin-top: 2rem;
  font-weight: bold;
}
</style>
