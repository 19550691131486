<template>
  <v-container v-if="!loading" fluid>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="2"
            block
            x-large
            color="#27AE60"
            v-bind="attrs"
            v-on="on"
          >
            <span class="white--text">休憩を追加</span>
          </v-btn>
        </template>
        <v-card>
          <div class="justify-end d-flex">
            <v-btn icon color="white" @click="dialog = false">
              <v-avatar color="#FF8585" size="15">
                <v-icon small>mdi-close</v-icon></v-avatar
              >
            </v-btn>
          </div>
          <v-card-title class="text-h5 justify-center d-flex">
            <div class="text-subtitle-1 text-primary">休憩を追加</div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" sm="6">
                <v-select
                  :items="types"
                  item-text="text"
                  return-object
                  solo
                  class="select-type"
                  v-model="type"
                  @change="updateFormType"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="6" class="d-flex">
                <div>
                  <v-text-field
                    class="mr-5"
                    outlined
                    v-model="formData.resting_minutes"
                  ></v-text-field>
                </div>
                <div class="mt-4">分</div>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" sm="12">
                <div>
                  <SubmitModal @submit="addRest" :rest="formData" />
                </div>
                <div class="text-center mt-5">
                  <v-btn
                    elevation="2"
                    block
                    x-large
                    color="#ffffff"
                    @click="dialog = false"
                  >
                    <span class="grey--text">キャンセル</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitModal from "./SubmitModal.vue";
export default {
  components: { SubmitModal },
  data() {
    return {
      loading: false,
      dialog: false,
      types: [
        { text: "昼休憩", value: 0 },
        { text: "夜休憩", value: 1 },
      ],
      type: null,
      formData: {
        type: null,
        resting_minutes: null,
      },
    };
  },

  computed: {
    ...mapGetters(["worktime"]),
  },

  methods: {
    addRest() {
      this.formData.worktime_id = this.worktime.id;
      // this.loading = true;
      // this.dialog = true;
      this.$store
        .dispatch("CREATE_WORKTIME_REST", this.formData)
        .then((result) => {
          this.loading = false;
          if (result.status === 200) {
            this.dialog = true;
            setTimeout(() => {
              this.dialog = false;
              // this.$router.push({
              //   name: "tasksDetails",
              //   params: { id: this.formData.task_id },
              // });
            }, 5000);
          }
        });
    },
    updateFormType(a) {
      this.formData.type = a.value;
    },
  },
};
</script>
