<template>
  <v-row>
    <v-col cols="12" sm="12">
      <div class="mb-5">
        <AddRestTime />
      </div>
      <div>
        <ClockOutConfirmation :worktime="worktime" />
      </div>
      <div class="text-center mt-5">
        <p @click="goBack()">キャンセル</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AddRestTime from "./AddRestTime.vue";
import ClockOutConfirmation from "./ClockOutConfirmation.vue";
export default {
  components: { AddRestTime, ClockOutConfirmation },
  props: ["task_id", "worktime"],
  methods: {
    goBack() {
      this.$router.push('/task/' + this.task_id);
    }
  }
};
</script>

<style>
.select-type {
  text-align: center;
}
</style>
